<template>
  <v-container fluid>
    <!-- Título y descripción -->
    <v-row class="px-5 pt-5 w-100" no-gutters>
      <div class="d-flex justify-between" style="width: 100%">
        <h2
          class="font-weight-bold mb-2"
          style="font-size: 24px; margin: 0; flex-grow: 1"
        >
          Nuevo producto
        </h2>
        <v-btn
          outlined
          rounded
          color="deep-purple lighten-1"
          dense
          style="padding: 0 0 0 12px; display: flex"
        >
          <span class="mr-1 text-none text-body-1">Activo</span>
          <v-icon size="35">mdi-check-circle</v-icon>
        </v-btn>
      </div>
      <p>Ingresa la información del producto</p>
    </v-row>

    <!-- Formulario -->
    <v-form v-model="valid">
      <!-- Información General -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Información general
          </v-card-title>
          <v-card-text>
            <!-- Código de barras -->
            <div class="mb-2">
              <label class="d-flex align-center font-weight-bold mb-3">
                Código de barras
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Ingrese o escanee el código de barras del producto.</span
                  >
                </v-tooltip>
              </label>
              <pat-input
                placeholder="0 000000 000000 (Opcional)"
                v-model="product.BarCode"
                appendIcon="mdi-barcode-scan"
                :messages="['Escanea el código de barras o digítalo']"
              />
            </div>

            <!-- SKU -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                SKU <span class="textgrey--text"> &nbsp;(Opcional)</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Agrega el SKU del producto (Opcional).</span>
                </v-tooltip>
              </label>
              <pat-input placeholder="(Opcional)" v-model="product.sku" />
            </div>

            <!-- Nombre del producto -->
            <pat-input
              label="Nombre del producto"
              placeholder="Ej. Jarabe"
              v-model="product.productName"
              :rules="[rules.required]"
              class="mb-5"
            />

            <!-- Marca -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Marca <span class="textgrey--text"> &nbsp;(Opcional)</span>
              </label>
              <pat-input placeholder="(Opcional)" v-model="product.brand" />
            </div>

            <!-- Categoría -->
            <div class="mb-4">
              <label class="d-flex align-center font-weight-bold mb-3">
                Categoría
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Seleccione la categoría correspondiente al producto.</span
                  >
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona una categoría"
                :items="categoriesFilter"
                v-model="product.selectedCategory"
                :rules="[rules.required]"
              />
            </div>

            <!-- Subcategoría -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Subcategoría
                <span class="textgrey--text"> &nbsp;(Opcional)</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Seleccione una subcategoría específica, si aplica.</span
                  >
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona subcategoría (Opcional)"
                :items="subcategories"
                v-model="product.selectedSubcategory"
              />
            </div>

            <!-- Producto controlado -->
            <v-radio-group v-model="product.controlledProduct" class="">
              <v-radio
                label="Producto controlado o requiere receta médica"
                value="true"
              />
            </v-radio-group>

            <!-- Descripción -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Descripción
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Agregue información adicional sobre el producto.</span>
                </v-tooltip>
              </label>
              <pat-input
                textarea
                placeholder="Agrega información adicional a tu producto (Opcional)"
                v-model="product.description"
              />
            </div>

            <!-- Proveedor o fabricante -->
            <pat-input
              label="Proveedor o fabricante"
              placeholder="(Opcional)"
              v-model="product.supplier"
              class="mb-5"
            />

            <!-- Unidad de venta -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Unidad de venta
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Seleccione la unidad de venta del producto.</span>
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona unidad de venta"
                :items="unitsOfSale"
                v-model="product.selectedUnitOfSale"
                :rules="[rules.required]"
              />
            </div>

            <!-- Contenido -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Contenido por caja
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Agrega contenido total de la caja.</span>
                </v-tooltip>
              </label>
              <pat-input placeholder="(Opcional)" v-model="product.content" />
            </div>

            <!-- Unidad de medida -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Unidad de medida
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Seleccione la unidad de medida del producto.</span>
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona unidad de medida"
                :items="unitsOfMeasurement"
                v-model="product.selectedUnitOfMeasurement"
                :rules="[rules.required]"
              />
            </div>

            <!-- Cantidad -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Cantidad en gramos
                <span class="textgrey--text"> &nbsp;(Opcional)</span>
              </label>
              <pat-input placeholder="(Opcional)" v-model="product.quantity" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Especificaciones -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Especificaciones
          </v-card-title>
          <v-card-text>
            <!-- Lista de sustancias activas -->
            <div
              v-for="(substance, index) in product.activeSubstances"
              :key="index"
              class="mb-5"
            >
              <!-- Input de Sustancia Activa -->
              <pat-input
                class="mb-2"
                label="Sustancia Activa"
                placeholder="Naranja en polvo"
                v-model="substance.name"
              >
                <template #append>
                  <v-icon
                    class="cursor-pointer"
                    @click="removeActiveSubstance(index)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </template>
              </pat-input>

              <!-- Concentración y Unidad -->
              <div class="d-flex justify-space-between">
                <div class="mr-2">
                  <pat-input
                    type="number"
                    label="Concentración"
                    placeholder="500"
                    v-model="substance.concentration"
                    class="mt-1"
                    style="min-width: 100px"
                    :rules="[rules.isNumber]"
                  />
                </div>
                <div>
                  <pat-select
                    label="Unidad"
                    :items="unityOptions"
                    placeholder="MG"
                    v-model="substance.unity"
                    class="mt-1"
                    style="min-width: 100px"
                  />
                </div>
              </div>
            </div>

            <!-- Opción "No Aplica" -->
            <v-radio-group v-model="product.noActiveSubstance">
              <v-radio
                label="No aplica"
                value="true"
                class="mt-2"
                hide-details
              />
            </v-radio-group>

            <!-- Botón para agregar sustancia activa -->
            <v-btn
              text
              color="primary"
              class="mt-2 d-flex align-center text-subtitle-1 font-weight-bold"
              @click="addActiveSubstance"
            >
              <v-icon
                left
                color="primary"
                class="x align-center"
                style="font-size: 24px"
              >
                mdi-plus
              </v-icon>
              <span class="text-decoration-underline"
                >Agregar Sustancia Activa</span
              >
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Carga de Imagen del Producto -->
      <v-col cols="12" md="12" class="d-flex" @click="openModalUploadPhoto">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 99%"
        >
          <div
            class="text-center d-flex flex-column align-center justify-center pa-8"
            style="border: 1px solid #000; height: 200px; width: 100%"
          >
            <v-img
              v-if="product.imageUrl"
              :src="product.imageUrl"
              max-height="200"
              contain
              alt="Imagen del producto"
            />
            <v-icon v-else size="50" color="black"
              >mdi-image-plus-outline</v-icon
            >
            <p
              v-if="!product.imageUrl"
              class="mt-2 text-subtitle-2 textgrey--text"
            >
              Da clic aquí para tomar una fotografía o subir un archivo
            </p>
          </div> -->
          <productUploadCard></productUploadCard>
        </v-card>
      </v-col>

      <!-- Variantes -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Variantes
          </v-card-title>
          <v-card-text>
            <!-- Botón de agregar variante -->
            <v-btn
              text
              color="primary"
              class="mt-2 d-flex align-center text-subtitle-1 font-weight-bold"
              @click="addVariant"
            >
              <v-icon left color="primary">mdi-plus</v-icon>
              <span class="text-decoration-underline">Agregar Variante</span>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Impuestos -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Impuestos
          </v-card-title>
          <v-card-text>
            <!-- Radio button para impuestos -->
            <v-radio-group v-model="product.chargeTax">
              <v-radio
                label="Cobrar impuestos del producto"
                value="true"
                hide-details
              />
            </v-radio-group>

            <!-- Selectores de IVA e IEPS -->
            <div class="d-flex justify-space-between mt-2">
              <div class="mr-2">
                <pat-select
                  label="IVA"
                  :items="ivaOptions"
                  placeholder="Selecciona"
                  v-model="product.iva"
                  class="mt-1"
                  style="min-width: 100px"
                />
              </div>
              <div>
                <pat-select
                  label="IEPS"
                  :items="iepsOptions"
                  placeholder="Selecciona"
                  v-model="product.ieps"
                  class="mt-1"
                  style="min-width: 100px"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Facturación -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Facturación
          </v-card-title>
          <v-card-text>
            <!-- Clave SAT -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Clave SAT
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Busca por palabras clave de tu producto.</span>
                </v-tooltip>
              </label>
              <pat-input
                placeholder="Busca por palabras clave de tu producto"
                v-model="product.satKey"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Modal Agregar Foto -->
      <div class="text-center">
        <v-dialog v-model="dialogAddPhoto" max-width="400">
          <v-card class="rounded-xl">
            <v-list class="rounded-xl" color="#454854">
              <!-- Opción: Galería de fotos -->
              <v-list-item @click="openGallery" class="white--text">
                <v-list-item-icon>
                  <v-icon color="white">mdi-image-plus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Galería de fotos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <!-- Opción: Tomar foto -->
              <v-list-item @click="takePhoto" class="white--text">
                <v-list-item-icon>
                  <v-icon color="white">mdi-camera-plus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Tomar foto</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <!-- Opción: Seleccionar archivo -->
              <v-list-item @click="openFileExplorer" class="white--text">
                <v-list-item-icon>
                  <v-icon color="white">mdi-folder-open-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Seleccionar archivo</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>

        <!-- Input oculto para seleccionar la imagen o cualquier archivo -->
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          capture="camera"
          style="display: none"
          @change="handleFileChange"
        />
      </div>

      <!-- Modal Agregar Variante -->
      <div class="text-center">
        <v-dialog
          v-model="dialogAddVariant"
          max-width="400"
          persistent
          content-class="custom-dialog-content"
        >
          <div class="position-relative">
            <!-- Tarjeta principal del contenido -->
            <v-card
              class="rounded-xl pa-6"
              style="
                background-color: #f4f4fa;
                position: relative;
                overflow: visible;
              "
            >
              <!-- Botón de cierre flotante -->
              <v-btn
                outlined
                color="primary"
                class="py-4 border-3"
                icon
                style="
                  position: absolute;
                  top: -40px;
                  right: 0px;
                  border-radius: 50%;
                  border-width: 3px;
                  background-color: #fff;
                "
                @click="closedialogAddVariant"
              >
                <v-icon color="primary">mdi-close</v-icon>
              </v-btn>

              <v-card-title>
                <h3 class="text-h5 font-weight-bold text-center mb-5">
                  Agregar variante
                </h3>
              </v-card-title>
              <v-card-text>
                <pat-select
                  label="Opción"
                  class="my-5"
                  placeholder="Selecciona una categoría"
                  :items="categoriesFilter"
                  v-model="product.variant.option"
                />
                <pat-input
                  label="Descripción"
                  placeholder="Ej. Jarabe"
                  v-model="product.variant.description"
                  class="mb-5"
                />
                <!-- Mensaje de error -->
                <v-col cols="12" class="d-flex justify-center" v-if="showError">
                  <div
                    class="d-flex align-center red lighten-5 pa-2 rounded-xl"
                  >
                    <v-icon color="error" class="mr-2">mdi-alert-circle</v-icon>
                    <span class="error--text">
                      Completa los campos obligatorios para continuar
                    </span>
                  </div>
                </v-col>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  class="py-6"
                  @click="saveVariant"
                  color="grey primary"
                  dark
                  block
                  rounded
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-dialog>
      </div>

      <v-card-actions class="justify-center">
        <v-btn
          :disabled="!valid"
          class="py-6"
          @click="submitForm"
          :color="valid ? 'primary' : 'grey darken-1'"
          block
          rounded
        >
          Continuar
        </v-btn>
      </v-card-actions>
    </v-form>

    <!-- Modal existencia y precio producto -->
    <div class="text-center">
      <v-dialog
        v-model="dialogProductInformation"
        max-width="400"
        persistent
        content-class="custom-dialog-content"
      >
        <div class="position-relative">
          <!-- Tarjeta principal del contenido -->
          <v-card class="rounded-xl pa-6" style="background-color: #f4f4fa">
            <!-- Botón de cierre flotante -->
            <v-btn
              outlined
              color="primary"
              class="py-4 border-3"
              icon
              style="
                position: absolute;
                top: -40px;
                right: -10px;
                border-radius: 50%;
                border-width: 3px;
                background-color: #fff;
              "
              @click="closeDialogProductInformation"
            >
              <v-icon color="primary">mdi-close</v-icon>
            </v-btn>

            <!-- Imagen y título -->
            <v-card-title
              class="text-h5 font-weight-bold text-center"
              style="
                white-space: normal;
                overflow-wrap: normal;
                word-break: normal;
                padding-bottom: 24px;
              "
            >
              <v-img
                src="../assets/info.png"
                contain
                max-height="100"
                max-width="100"
                alt="Icono de productos"
                class="mb-4 mx-auto"
              ></v-img>
              ¿Quieres continuar agregando la existencia y precio del producto?
            </v-card-title>

            <v-card-actions
              class="d-flex flex-column items-center align-center"
            >
              <v-btn
                @click="goto('branch-no-lot')"
                class="py-4"
                color="primary"
                dark
                block
                rounded
                >Continuar</v-btn
              >

              <v-col cols="12" md="8" class="d-flex justify-center mt-3">
                <p
                  class="primary--text text-decoration-underline font-weight-bold"
                  style="cursor: pointer"
                  @click="saveProduct"
                >
                  En otro momento
                </p>
              </v-col>
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      product: {
        imageUrl: null,
        isActive: true,
        productName: "",
        supplier: "",
        brand: "",
        selectedCategory: "",
        selectedSubcategory: "",
        controlledProduct: "",
        description: "",
        content: "",
        quantity: "",
        BarCode: "",
        sku: "",
        selectedUnitOfSale: "",
        selectedUnitOfMeasurement: "",
        noActiveSubstance: false,
        activeSubstances: [],
        chargeTax: "",
        iva: "",
        ieps: "",
        satKey: "",
        variant: {
          option: "",
          description: "",
        },
      },
      categoriesFilter: [
        "Todas",
        "Medicamento",
        "Botiquín",
        "Vitaminas",
        "Dermatológico",
        "Alimentos",
        "Belleza",
      ],
      subcategories: ["Subcategoría 1", "Subcategoría 2", "Subcategoría 3"],
      unitsOfSale: ["Unidad 1", "Unidad 2", "Unidad 3"],
      unitsOfMeasurement: ["Gramos", "Kilogramos", "Litros", "Mililitros"],
      unityOptions: ["MG", "ML", "G", "L"],
      ivaOptions: ["16%", "8%", "0%"],
      iepsOptions: ["Selección 1", "Selección 2", "Selección 3"],
      valid: false,
      showError: false,
      dialogAddVariant: false,
      dialogAddPhoto: false,
      dialogProductInformation: false,
      rules: {
        required: (value) => !!value || "Este campo es obligatorio",
        isNumber: (value) => {
          return (
            !value || /^-?\d+(\.\d+)?$/.test(value) || "Debe ser un número."
          );
        },
      },
    };
  },
  methods: {
    addActiveSubstance() {
      this.product.activeSubstances.push({
        name: "",
        concentration: "",
        unity: "",
      });
    },
    removeActiveSubstance(index) {
      console.log("Eliminando sustancia activa en índice:", index);
      this.product.activeSubstances.splice(index, 1);
    },
    addVariant() {
      this.dialogAddVariant = true;
    },
    closedialogAddVariant() {
      this.dialogAddVariant = false;
      this.showError = false;
    },
    saveVariant() {
      const { option, description } = this.product.variant;
      if (!option || !description) {
        this.showError = true;
        return;
      }
      this.showError = false;
      this.dialogAddVariant = false;
    },
    openModalUploadPhoto() {
      this.dialogAddPhoto = true;
    },
    openGallery() {
      this.dialogAddPhoto = false;
      this.$refs.fileInput.click();
    },
    takePhoto() {
      this.dialogAddPhoto = false;
      this.$refs.fileInput.click();
    },
    openFileExplorer() {
      this.dialogAddPhoto = false;
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.product.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    submitForm() {
      if (this.valid) {
        this.dialogProductInformation = true;
      }
    },
    closeDialogProductInformation() {
      this.dialogProductInformation = false;
    },
    goto(route) {
      this.$router.push({ name: route });
    },
    saveProduct() {
      this.dialogProductInformation = false;
      console.log("Guardando producto...");
      console.log(this.product);
    },
  },
};
</script>
<style scoped>
.custom-dialog-content {
  overflow: visible !important;
}
.custom-dialog-overlay {
  overflow: visible !important;
}
</style>
